import React, { memo, useMemo } from 'react';
import { PERSONAS, DEFAULT_EMOTIONAL_STATE } from '../constants';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';

const tooltipDescriptions = {
  valence: "Measures the positivity or negativity of emotional state, from very negative (-5) to very positive (+5)",
  arousal: "Indicates energy level and intensity of emotions, from calm/relaxed (0) to highly energetic/intense (10)",
  dominance: "Reflects feelings of control and influence, from feeling powerless (0) to feeling in control (10)",
  openness: "Shows willingness to engage and share, from closed-off (0) to very receptive (10)",
  trust: "Indicates level of trust and comfort, from highly suspicious (0) to completely trusting (10)",
  pain: "Measures physical or emotional discomfort, from no pain (0) to severe pain (10)",
  anxiety: "Reflects level of worry or unease, from completely calm (0) to severely anxious (10)"
};

// Add emotion descriptions
const emotionDescriptions = {
  V: {
    full: "Valence",
    description: "Measures the positivity or negativity of emotional state, from very negative (-5) to very positive (+5)"
  },
  A: {
    full: "Arousal",
    description: "Indicates energy level and intensity of emotions, from calm/relaxed (0) to highly energetic/intense (10)"
  },
  D: {
    full: "Dominance",
    description: "Reflects feelings of control and influence, from feeling powerless (0) to feeling in control (10)"
  },
  O: {
    full: "Openness",
    description: "Shows willingness to engage and share, from closed-off (0) to very receptive (10)"
  },
  T: {
    full: "Trust",
    description: "Indicates level of trust and comfort, from highly suspicious (0) to completely trusting (10)"
  },
  P: {
    full: "Pain",
    description: "Measures physical or emotional discomfort, from no pain (0) to severe pain (10)"
  },
  Ax: {
    full: "Anxiety",
    description: "Reflects level of worry or unease, from completely calm (0) to severely anxious (10)"
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const current = payload[0].value;
    const previous = payload[1]?.value;
    const change = previous !== undefined ? (current - previous).toFixed(1) : null;
    
    // Map short labels to full names and get descriptions
    const getEmotionDetails = (label) => {
      const mapping = {
        'V': { name: 'Valence', description: value => value > 0 ? 'Positive Mood' : 'Negative Mood' },
        'A': { name: 'Arousal', description: value => value > 5 ? 'High Energy' : 'Low Energy' },
        'D': { name: 'Dominance', description: value => value > 5 ? 'Feeling in Control' : 'Feeling Helpless' },
        'O': { name: 'Openness', description: value => value > 5 ? 'Very Open' : 'Reserved' },
        'T': { name: 'Trust', description: value => value > 5 ? 'Trusting' : 'Guarded' },
        'P': { name: 'Pain', description: value => value > 5 ? 'High Pain' : 'Minimal Pain' },
        'Ax': { name: 'Anxiety', description: value => value > 5 ? 'Very Anxious' : 'Calm' }
      };
      return mapping[label] || { name: label, description: () => '' };
    };

    const emotion = getEmotionDetails(label);
    const description = emotion.description(current);

    return (
      <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        padding: '12px',
        border: '1px solid #666',
        borderRadius: '4px',
        color: '#fff',
        fontSize: '0.9em',
        maxWidth: '200px'
      }}>
        <div style={{ 
          marginBottom: '4px', 
          fontWeight: 'bold',
          color: '#c59ad1e6'
        }}>
          {emotion.name}
        </div>
        <div style={{ marginBottom: '8px' }}>
          {description} ({current})
        </div>
        {change && (
          <div style={{ 
            color: change > 0 ? '#4CAF50' : change < 0 ? '#f44336' : '#888',
            fontSize: '0.8em'
          }}>
            {change > 0 ? '↑' : change < 0 ? '↓' : '='} {Math.abs(change)}
            <span style={{ color: '#888' }}> since previous</span>
          </div>
        )}
      </div>
    );
  }
  return null;
};

// Add this helper function at the top
const getEmotionalCategory = (state) => {
  const valence = state?.valence || 0;
  const arousal = state?.arousal || 5;
  const anxiety = state?.anxiety || 5;

  // Determine base emotion
  if (valence < -2) return 'Sad';
  if (valence > 2) return 'Joyful';
  if (anxiety > 7) return 'Fearful';
  if (arousal > 7) return 'Surprised';
  if (arousal < 3) return 'Bored';
  return 'Interested';
};

const EmotionalState = memo(({ state, previousState, analysis, persona }) => {
  const currentState = {
    ...DEFAULT_EMOTIONAL_STATE,
    ...(state || {})
  };

  const chartData = useMemo(() => [
    { attribute: 'V', current: currentState?.valence || 0, previous: previousState?.valence, fullMark: 5 },
    { attribute: 'A', current: currentState?.arousal || 5, previous: previousState?.arousal, fullMark: 10 },
    { attribute: 'D', current: currentState?.dominance || 5, previous: previousState?.dominance, fullMark: 10 },
    { attribute: 'O', current: currentState?.openness || 5, previous: previousState?.openness, fullMark: 10 },
    { attribute: 'T', current: currentState?.trust || 5, previous: previousState?.trust, fullMark: 10 },
    { attribute: 'P', current: currentState?.pain || 0, previous: previousState?.pain, fullMark: 10 },
    { attribute: 'Ax', current: currentState?.anxiety || 5, previous: previousState?.anxiety, fullMark: 10 },
  ], [currentState, previousState]);

  return (
    <div style={{
      backgroundColor: '#111',
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
      maxWidth: '600px',
      margin: '0',
      border: '1px solid #333',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto'
    }}>
      {/* Add emotional category display */}
      <div style={{
        marginBottom: '20px',
        padding: '10px',
        backgroundColor: '#1a1a1a',
        borderRadius: '8px',
        textAlign: 'center',
        color: '#c59ad1e6',
        fontSize: '1.2em',
        fontWeight: '500'
      }}>
        Current Emotional State: {getEmotionalCategory(state)}
      </div>

      <div style={{ height: '400px', marginBottom: '20px', flex: '1 1 auto' }}>
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
            <PolarGrid stroke="#333" />
            <PolarAngleAxis 
              dataKey="attribute" 
              tick={{ fill: '#E0E0E0' }}
              tickFormatter={(value) => value}
            />
            <PolarRadiusAxis angle={30} domain={[0, 10]} tick={{ fill: '#E0E0E0' }} />
            {previousState && (
              <Radar
                name="Previous"
                dataKey="previous"
                stroke="#666"
                fill="#666"
                fillOpacity={0.3}
              />
            )}
            <Radar
              name="Current"
              dataKey="current"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.6}
            />
            <Tooltip content={<CustomTooltip />} />
          </RadarChart>
        </ResponsiveContainer>
      </div>

      {analysis && (
        <div style={{
          marginTop: '20px',
          padding: '15px',
          backgroundColor: '#1a1a1a',
          borderRadius: '8px',
          fontSize: '0.9em',
          color: '#c59ad1e6',
          borderLeft: '4px solid #3a59b5',
          flex: '0 0 auto'
        }}>
          <h3 style={{ 
            marginBottom: '10px', 
            color: '#E0E0E0',
            fontSize: '1em',
            fontWeight: '500'
          }}>
            Emotional Shift Analysis
          </h3>
          <p style={{ margin: 0, lineHeight: '1.4' }}>
            {analysis}
          </p>
        </div>
      )}
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.state) === JSON.stringify(nextProps.state) &&
    JSON.stringify(prevProps.previousState) === JSON.stringify(nextProps.previousState) &&
    JSON.stringify(prevProps.analysis) === JSON.stringify(nextProps.analysis) &&
    prevProps.persona?.id === nextProps.persona?.id
  );
});

EmotionalState.displayName = 'EmotionalState';

export default EmotionalState; 