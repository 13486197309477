// client/src/App.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import io from 'socket.io-client';
import EmotionalState from './components/EmotionalState';
import AudioVisualizer from './components/AudioVisualizer';
import Drawer from '@mui/material/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn, faImages, faBookOpen, faLink, faListCheck, faVolumeHigh, faVolumeMute, faCircle, faCircleXmark, faComments, faXmark } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { APP_CONFIG, PERSONAS, DEFAULT_EMOTIONAL_STATE, generateSystemPrompt } from './config/personas';

const socket = io('https://openpossibilities.org', {
  withCredentials: true,
  transports: ['polling', 'websocket'],
  secure: true,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
  autoConnect: true,
  path: '/socket.io/',
});

// Audio constraints
const AUDIO_CONSTRAINTS = {
  sampleRate: 16000,
  channelCount: 1,
  echoCancellation: true,
  noiseSuppression: true,
  autoGainControl: true,
};

// Voice detection constants
const VOICE_DETECTION_CONSTANTS = {
  VOICE_THRESHOLD: -25,          // Less strict threshold to catch softer speech (was -15)
  SILENCE_DURATION: 1000,        // Shorter silence duration for more responsive experience (was 2000)
  MIN_RECORDING_DURATION: 500,   // Shorter minimum to catch quick responses (was 2000)
  MAX_RECORDING_DURATION: 30000, // Longer maximum for longer responses (was 15000)
  RMS_THRESHOLD: 0.01,          // Additional RMS threshold to ensure actual speech
  CONSECUTIVE_VOICE_FRAMES: 3    // Number of consecutive frames above threshold to confirm voice
};

// Update image references to use the new PERSONAS structure
const getPersonaImagePath = (persona, emotionalState) => {
  console.log('getPersonaImagePath details:', {
    persona,
    emotionalState,
    hasImageState: emotionalState?.imageState,
    fullPath: emotionalState?.imageState ? 
      `/images/personas/${persona.id}/${emotionalState.imageState}.png` : 
      'using default'
  });

  if (!emotionalState?.imageState) {
    console.warn('Missing imageState, using default');
    return `/images/personas/${persona.id}/interested_med1.png`;
  }

  const imagePath = `/images/personas/${persona.id}/${emotionalState.imageState}.png`;
  return imagePath;
};

// Function to get supported MIME type
const getSupportedMimeType = () => {
  const possibleTypes = [
    'audio/webm',
    'audio/webm;codecs=opus',
    'audio/ogg;codecs=opus',
    'audio/wav',
  ];

  for (const mimeType of possibleTypes) {
    if (MediaRecorder.isTypeSupported(mimeType)) {
      console.log('Using MIME type:', mimeType);
      return mimeType;
    }
  }

  throw new Error('No supported MIME type found');
};

// Silence modal component
const SilenceModal = ({ onResume, onEnd }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: '#111',
          padding: '30px',
          borderRadius: '12px',
          maxWidth: '400px',
          border: '1px solid #333',
          color: '#E0E0E0',
        }}
      >
        <h3 style={{ marginBottom: '20px' }}>No Activity Detected</h3>
        <p style={{ marginBottom: '20px' }}>
          The conversation has been inactive for 2 minutes. Would you like to
          continue or end the session?
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <button
            onClick={onEnd}
            style={{
              padding: '10px 20px',
              backgroundColor: '#c59ad1e6',
              color: '#E0E0E0',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            End Session
          </button>
          <button
            onClick={onResume}
            style={{
              padding: '10px 20px',
              backgroundColor: '#3a59b5',
              color: '#E0E0E0',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

// Adjusted VerticalTab component
const VerticalTab = ({ isOpen, onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'fixed',
      left: isOpen ? '350px' : '0',
      top: '80px',
      backgroundColor: '#333',
      border: '1px solid #333',
      borderLeft: 'none',
      borderRadius: '0 4px 4px 0',
      cursor: 'pointer',
      padding: '12px 8px',
      userSelect: 'none',
      color: '#c59ad1e6',
      fontSize: '20px',
      transition: 'left 0.2s ease-in-out',
      zIndex: 1200,
    }}
  >
    <FontAwesomeIcon icon={faChartColumn} />
  </div>
);

// Add this new component for the vertical tabs
const VerticalTabs = ({ openDrawer, setOpenDrawer }) => {
  const tabs = [
    { id: 'emotional', icon: faChartColumn, label: 'Emotional State' },
    { id: 'conversation', icon: faComments, label: 'Conversation' },
    { id: 'images', icon: faImages, label: 'Images' },
    { id: 'instructions', icon: faBookOpen, label: 'Instructions' },
    { id: 'reference', icon: faLink, label: 'Reference' },
    { id: 'checklist', icon: faListCheck, label: 'Checklist' },
  ];

  return (
    <div style={{ 
      position: 'fixed', 
      left: openDrawer !== null ? '350px' : '0', 
      top: '80px', 
      zIndex: 1200,
      transition: 'left 0.2s ease-in-out',
    }}>
      {tabs.map((tab) => (
        <Tooltip 
          key={tab.id} 
          title={tab.label} 
          placement="right"
          arrow
        >
          <div
            onClick={() => setOpenDrawer(openDrawer === tab.id ? null : tab.id)}
            style={{
              backgroundColor: '#333',
              border: '1px solid #333',
              borderLeft: 'none',
              borderRadius: '0 4px 4px 0',
              cursor: 'pointer',
              padding: '12px 8px',
              marginBottom: '4px',
              userSelect: 'none',
              color: openDrawer === tab.id ? '#fff' : '#c59ad1e6',
              fontSize: '20px',
            }}
            className={openDrawer === tab.id ? 'pulse-animation' : ''}
          >
            <FontAwesomeIcon icon={tab.icon} />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

// Add this component for drawer content
const DrawerContent = ({ type, emotionalState, previousState, emotionalAnalysis, conversation, persona, onClose }) => {
  useEffect(() => {
    console.log('DrawerContent emotional state:', {
      type,
      emotionalState,
      previousState,
      emotionalAnalysis,
      persona
    });
  }, [type, emotionalState, previousState, emotionalAnalysis, persona]);

  const content = {
    emotional: (
      <div className="drawer-content">
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '20px',
          borderBottom: '1px solid #444',
          paddingBottom: '10px'
        }}>
          <h2 style={{ 
            margin: 0,
            color: '#ffffff'
          }}>{persona.name}'s Emotional State</h2>
          <FontAwesomeIcon 
            icon={faXmark} 
            style={{ 
              cursor: 'pointer',
              fontSize: '20px',
              color: '#c59ad1e6',
              padding: '8px',
              borderRadius: '4px',
              transition: 'background-color 0.2s',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
            onClick={onClose}
          />
        </div>
        <EmotionalState
          state={emotionalState}
          previousState={previousState}
          analysis={emotionalAnalysis}
          persona={persona}
        />
      </div>
    ),
    images: (
      <div className="drawer-content">
        <h2>Image Library</h2>
        <p>Browse and manage available persona images.</p>
        {/* Add image grid or list here */}
      </div>
    ),
    instructions: (
      <div className="drawer-content">
        <h2 style={{ color: '#ffffff' }}>Instructions</h2>
        <ul style={{ color: '#E0E0E0' }}>
          <li>Step 1: Begin conversation by clicking "Start Listening"</li>
          <li>Step 2: Speak clearly into your microphone</li>
          <li>Step 3: Wait for AI response</li>
          <li>Step 4: Continue natural conversation</li>
        </ul>
      </div>
    ),
    reference: (
      <div className="drawer-content">
        <h2 style={{ color: '#ffffff' }}>Quick Reference</h2>
        <ul style={{ color: '#E0E0E0' }}>
          <li>Voice Commands</li>
          <li>Keyboard Shortcuts</li>
          <li>Common Phrases</li>
          <li>Troubleshooting</li>
        </ul>
      </div>
    ),
    checklist: (
      <div className="drawer-content">
        <h2 style={{ color: '#ffffff' }}>Session Checklist</h2>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: '10px',
          color: '#E0E0E0'
        }}>
          <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input type="checkbox" /> Microphone check
          </label>
          <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input type="checkbox" /> Audio output check
          </label>
          <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input type="checkbox" /> Review instructions
          </label>
          <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input type="checkbox" /> Set session goals
          </label>
        </div>
      </div>
    ),
    conversation: (
      <div className="drawer-content">
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '20px',
          borderBottom: '1px solid #444',
          paddingBottom: '10px'
        }}>
          <h2 style={{ 
            margin: 0,
            color: '#ffffff'
          }}>Conversation History</h2>
          <FontAwesomeIcon 
            icon={faXmark} 
            style={{ 
              cursor: 'pointer',
              fontSize: '20px',
              color: '#c59ad1e6',
              padding: '8px',
              borderRadius: '4px',
              transition: 'background-color 0.2s'
            }}
            onClick={onClose}
          />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 150px)',
          padding: '10px',
        }}>
          {conversation.map((message) => (
            <div
              key={message.timestamp}
              style={{
                alignSelf: message.type === 'user' ? 'flex-start' : 'flex-end',
                backgroundColor: message.type === 'user' ? '#e9ecef' : '#007bff',
                color: message.type === 'user' ? '#000000' : '#ffffff',
                padding: '10px 15px',
                borderRadius: '15px',
                maxWidth: '70%',
                whiteSpace: 'pre-wrap',
                opacity: message.complete === false ? 0.7 : 1,
                transition: 'opacity 0.3s ease',
              }}
            >
              {message.content}
            </div>
          ))}
        </div>
      </div>
    ),
  };

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>

      
      <div style={{
        flex: '1 1 auto',
        overflow: 'auto',
        padding: '20px'
      }}>
        {content[type]}
      </div>
    </div>
  );
};

// Add a new component for the right vertical tab
const RightVerticalTab = ({ isOpen, onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'fixed',
      right: isOpen ? '350px' : '0',
      top: '80px',
      backgroundColor: '#333',
      border: '1px solid #333',
      borderRight: 'none',
      borderRadius: '4px 0 0 4px',
      cursor: 'pointer',
      padding: '12px 8px',
      userSelect: 'none',
      color: '#c59ad1e6',
      fontSize: '20px',
      transition: 'right 0.2s ease-in-out',
      zIndex: 1200,
    }}
    className={isOpen === null ? 'pulse-animation' : ''}
  >
    <FontAwesomeIcon icon={faChartColumn} />
  </div>
);

// Add these utility functions at the top level
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

function App() {
  const [rightDrawerOpen, setRightDrawerOpen] = useState(null);
  const [aiResponse, setAiResponse] = useState('');
  const [isListening, setIsListening] = useState(false);
  const isListeningRef = useRef(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [inputLevel, setInputLevel] = useState(-100);
  const [outputLevel, setOutputLevel] = useState(-100);
  const persona_image_id = '0001';

  // Emotional state variables
  const [emotionalStates, setEmotionalStates] = useState({
    [PERSONAS.DAN.id]: DEFAULT_EMOTIONAL_STATE,
    [PERSONAS.ANNE.id]: DEFAULT_EMOTIONAL_STATE
  });

  const [previousStates, setPreviousStates] = useState({
    [PERSONAS.DAN.id]: null,
    [PERSONAS.ANNE.id]: null
  });

  const [emotionalAnalysis, setEmotionalAnalysis] = useState({
    [PERSONAS.DAN.id]: { analysis: '', emotionalState: null },
    [PERSONAS.ANNE.id]: { analysis: '', emotionalState: null }
  });

  // Refs
  const audioRef = useRef(null);
  const audioChunks = useRef([]);
  const cleanupRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const audioDataListenerAdded = useRef(false);
  const audioQueueRef = useRef([]);
  const isPlayingRef = useRef(false);
  const currentAudioRef = useRef(null);
  const mediaStreamRef = useRef(null);

  const [transcription, setTranscription] = useState('');
  const [conversation, setConversation] = useState(() => {
    const saved = localStorage.getItem('conversation');
    return saved ? JSON.parse(saved) : [];
  });

  // Previous emotional state
  const [previousState, setPreviousState] = useState(null);
  // const [emotionalAnalysis, setEmotionalAnalysis] = useState(null);
  const [showSilenceModal, setShowSilenceModal] = useState(false);
  const [isServerProcessing, setIsServerProcessing] = useState(false);
  const [isAISpeaking, setIsAISpeaking] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(null);

  // Add this state to track audio completion
  const [isAudioComplete, setIsAudioComplete] = useState(true);

  // Inside the App component, add this state to track which persona is speaking
  const [speakingPersona, setSpeakingPersona] = useState(null);

  // Add this near the top with other state declarations in the App component
  const [ttsSpeed, setTtsSpeed] = useState(1.0);

  useEffect(() => {
    document.title = 'ATLAS by Activesim';
  }, []);


  useEffect(() => {
    localStorage.setItem('conversation', JSON.stringify(conversation));
  }, [conversation]);

  const clearConversation = () => {
    setConversation([]);
    localStorage.removeItem('conversation');
  };

  // Start listening function
  const startListening = useCallback(() => {
    if (isListeningRef.current) return;
    console.log('startListening called');

    if (mediaStreamRef.current && mediaStreamRef.current.active) {
      console.log('Reusing existing media stream');
      const cleanup = processAudioStream(mediaStreamRef.current);
      cleanupRef.current = cleanup;
      setIsListening(true);
      isListeningRef.current = true;
    } else {
      navigator.mediaDevices
        .getUserMedia({
          audio: AUDIO_CONSTRAINTS  // Use the defined constraints
        })
        .then((stream) => {
          console.log('getUserMedia successful with constraints:', AUDIO_CONSTRAINTS);
          mediaStreamRef.current = stream;
          const cleanup = processAudioStream(stream);
          cleanupRef.current = cleanup;
          setIsListening(true);
          isListeningRef.current = true;
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error);
        });
    }
  }, []);

  // Stop listening function
  const stopListening = useCallback(() => {
    if (!isListeningRef.current) return;
    if (cleanupRef.current) {
      cleanupRef.current();
    }
    setIsListening(false);
    isListeningRef.current = false;
  }, []);

  // Process audio stream
  const processAudioStream = (stream) => {
    try {
      // iOS requires audio context to be created in response to a user gesture
      // and requires a different constructor name
      const audioContext = new (window.AudioContext || window.webkitAudioContext)({
        // iOS requires these specific audio settings
        sampleRate: 44100,
        latencyHint: 'interactive'
      });

      // iOS requires audio context to be resumed after creation
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }

      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      // Smaller FFT size for better performance on mobile
      analyser.fftSize = 1024;
      source.connect(analyser);

      // Data array for analysis
      const dataArray = new Float32Array(analyser.frequencyBinCount);

      // Track chunk timing
      let currentChunkStartTime = Date.now();

      const mimeType = getSupportedMimeType();
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType,
        audioBitsPerSecond: 128000,
      });

      // Helper function to convert blob to base64
      const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result.split(',')[1];
            resolve(base64data);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      };

      mediaRecorder.addEventListener('dataavailable', (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push({
            data: event.data,
            timestamp: currentChunkStartTime || Date.now(),
          });
        }
      });

      mediaRecorder.addEventListener('start', () => {
        currentChunkStartTime = Date.now();
        audioChunks.current = [];
      });

      mediaRecorder.addEventListener('stop', async () => {
        if (audioChunks.current.length === 0) return;

        try {
          // Create blob with correct MIME type
          const audioBlob = new Blob(
            audioChunks.current.map((chunk) => chunk.data),
            { type: mediaRecorder.mimeType }
          );

          // Send the audio data
          const base64data = await blobToBase64(audioBlob);
          socket.emit('audioStream', {
            audio: base64data,
            mimeType: mediaRecorder.mimeType,
            timestamp: Date.now(),
          });

          setIsServerProcessing(true);
          audioChunks.current = [];
          speaking = false;
          setIsSpeaking(false);
        } catch (error) {
          console.error('Error processing audio:', error);
        }
      });

      // Voice detection setup
      let consecutiveVoiceFrames = 0;
      let speaking = false;
      let silenceStart = null;
      let recordingStart = null;

      const detectVoice = () => {
        analyser.getFloatTimeDomainData(dataArray);
        
        // Calculate RMS (Root Mean Square) for better voice detection
        let sumSquares = 0;
        for (const amplitude of dataArray) {
          sumSquares += amplitude * amplitude;
        }
        const rms = Math.sqrt(sumSquares / dataArray.length);
        const db = 20 * Math.log10(Math.max(rms, 1e-10));
        setInputLevel(db);

        const voiceDetected = db > VOICE_DETECTION_CONSTANTS.VOICE_THRESHOLD && 
                             rms > VOICE_DETECTION_CONSTANTS.RMS_THRESHOLD;

        if (voiceDetected) {
          consecutiveVoiceFrames++;
          if (!speaking && consecutiveVoiceFrames >= VOICE_DETECTION_CONSTANTS.CONSECUTIVE_VOICE_FRAMES) {
            console.log('Voice detected at', Math.round(db), 'dB, RMS:', rms);
            mediaRecorder.start();
            speaking = true;
            recordingStart = Date.now();
            silenceStart = null;
            setIsSpeaking(true);
            consecutiveVoiceFrames = 0;
          }
          silenceStart = null;
        } else {
          consecutiveVoiceFrames = 0;
          if (speaking) {
            if (!silenceStart) {
              silenceStart = Date.now();
            } else {
              const silenceDuration = Date.now() - silenceStart;
              const recordingDuration = Date.now() - recordingStart;

              if ((silenceDuration > VOICE_DETECTION_CONSTANTS.SILENCE_DURATION && 
                   recordingDuration > VOICE_DETECTION_CONSTANTS.MIN_RECORDING_DURATION) || 
                  recordingDuration > VOICE_DETECTION_CONSTANTS.MAX_RECORDING_DURATION) {
                console.log('Stopping recording:', {
                  silenceDuration,
                  recordingDuration,
                  db,
                  rms
                });
                mediaRecorder.stop();
                speaking = false;
                setIsSpeaking(false);
              }
            }
          }
        }
      };

      // Start voice detection loop
      const detectionInterval = setInterval(detectVoice, 100);

      // Cleanup function
      return () => {
        clearInterval(detectionInterval);
        source.disconnect();
        audioContext.close();
        if (mediaRecorder.state !== 'inactive') {
          mediaRecorder.stop();
        }
        // Do not stop the media stream here
      };
    } catch (error) {
      console.error('Error setting up MediaRecorder:', error);
      return () => {};
    }
  };

  // Monitor output audio levels
  const monitorAudioOutput = (audioElement) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioContext.createMediaElementSource(audioElement);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;

    source.connect(analyser);
    analyser.connect(audioContext.destination);

    const dataArray = new Float32Array(analyser.frequencyBinCount);

    const updateLevel = () => {
      analyser.getFloatTimeDomainData(dataArray);
      let sumSquares = 0;
      for (const amplitude of dataArray) {
        sumSquares += amplitude * amplitude;
      }
      const rms = Math.sqrt(sumSquares / dataArray.length);
      const db = 20 * Math.log10(Math.max(rms, 1e-10));
      setOutputLevel(db);

      if (isPlayingRef.current) {
        requestAnimationFrame(updateLevel);
      } else {
        setOutputLevel(-100);
      }
    };

    return updateLevel;
  };

  // Toggle listening function
  const toggleListening = useCallback(() => {
    console.log(
      'Toggle listening called. AI Speaking:',
      isAISpeaking,
      'Server Processing:',
      isServerProcessing
    );

    if (isAISpeaking || isServerProcessing) {
      console.log('Cannot toggle while AI is speaking or server is processing');
      return;
    }

    if (isListeningRef.current) {
      // Stop the media stream tracks when pausing
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop());
        mediaStreamRef.current = null;
      }
      stopListening();
    } else {
      setIsServerProcessing(false);
      setIsAISpeaking(false);
      startListening();
    }
  }, [isAISpeaking, isServerProcessing, startListening, stopListening]);

  // Socket event handlers
  useEffect(() => {
    setAiResponse('');

    socket.on('connect', () => {
      console.log('Socket connected');
      setIsConnected(true);
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      setIsConnected(false);
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      setIsConnected(false);
    });

    socket.on('aiResponse', (chunk) => {
      console.log('Received AI response chunk:', chunk);

      // Check if the chunk starts with *Anne* or *Dan*
      const isAnne = chunk.trim().startsWith('*Anne*');
      const isDan = chunk.trim().startsWith('*Dan*');
      const currentPersona = isAnne ? PERSONAS.ANNE : isDan ? PERSONAS.DAN : null;
      setSpeakingPersona(currentPersona);

      // Send the voice preference to the server
      if (currentPersona) {
        socket.emit('setVoice', PERSONAS.ANNE.voice);
      }

      if (!isAISpeaking) {
        setIsAISpeaking(true);
      }

      setConversation((prev) => {
        if (prev.length > 0 && prev[0].type === 'ai' && !prev[0].complete) {
          return [
            {
              ...prev[0],
              content: prev[0].content + chunk,
              persona: currentPersona  // Add persona to the message
            },
            ...prev.slice(1),
          ];
        } else if (chunk === '\n') {
          setIsServerProcessing(false);
          return prev.map((msg, idx) => (idx === 0 ? { ...msg, complete: true } : msg));
        } else {
          return [
            {
              type: 'ai',
              content: chunk,
              timestamp: Date.now(),
              complete: false,
              persona: currentPersona  // Add persona to the message
            },
            ...prev,
          ];
        }
      });
    });

    socket.on('error', (error) => {
      console.error('Server error:', error);
      setAiResponse((prev) => prev + '\nError: ' + error);
    });

    socket.on('transcription', (text) => {
      console.log('Received transcription:', text);
      setConversation((prev) => [
        {
          type: 'user',
          content: text + '\n',
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    });


    socket.on('emotionalStateUpdate', (data) => {
      console.log('Received emotional state update (full data):', JSON.stringify(data, null, 2));

      if (data?.emotionalState) {
        const personaObj = data.persona === 'ANNE' ? PERSONAS.ANNE : 
                         data.persona === 'DAN' ? PERSONAS.DAN : null;
        
        if (personaObj) {
          setEmotionalStates(prev => ({
            ...prev,
            [personaObj.id]: {
              ...data.emotionalState,
              // The imageState might be getting lost here
              imageState: data.imageState || data.emotionalState.imageState
            }
          }));
        }
      }
    });
  
    socket.on('emotionalAnalysis', (data) => {
      console.log('Received emotional analysis:', data);
      if (data?.analysis) {
        const personaObj = data.persona === 'ANNE' ? PERSONAS.ANNE : 
                         data.persona === 'DAN' ? PERSONAS.DAN : null;
        
        if (personaObj) {
          console.log('Updating emotional analysis for:', personaObj.name);
          setEmotionalAnalysis(prev => ({
            ...prev,
            [personaObj.id]: {
              analysis: data.analysis.analysis,
              emotionalState: data.emotionalState
            }
          }));
        }
      }
    });  

    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('disconnect');
      socket.off('aiResponse');
      socket.off('error');
      socket.off('transcription');
      socket.off('emotionalStateUpdate');
      socket.off('emotionalAnalysis');
      if (cleanupRef.current) {
        cleanupRef.current();
      }
      audioChunks.current = [];
      setIsAISpeaking(false);
      setIsServerProcessing(false);
    };
  }, []);

  // Handle silence detection
  const handleSilenceDetection = useCallback(() => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }

    if (isListeningRef.current && !isSpeaking) {
      silenceTimeoutRef.current = setTimeout(() => {
        setShowSilenceModal(true);
        if (cleanupRef.current) {
          cleanupRef.current();
        }
        setIsListening(false);
        isListeningRef.current = false;
      }, 120000);
    }
  }, [isSpeaking]);

  // Monitor silence
  useEffect(() => {
    handleSilenceDetection();
    return () => {
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
    };
  }, [handleSilenceDetection, isSpeaking]);

  // Modal handlers
  const handleResume = () => {
    setShowSilenceModal(false);
  };

// Add this near the other handler functions, before the return statement
const handleSpeedChange = (event) => {
  const newSpeed = parseFloat(event.target.value);
  setTtsSpeed(newSpeed);
  
  // Update speed of currently playing audio
  if (currentAudioRef.current) {
    try {
      currentAudioRef.current.playbackRate = newSpeed;
    } catch (error) {
      console.error('Error updating playback rate:', error);
    }
  }
};

  const handleEndSession = () => {
    setShowSilenceModal(false);
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
      mediaStreamRef.current = null;
    }
    if (cleanupRef.current) {
      cleanupRef.current();
    }
    setIsListening(false);
    isListeningRef.current = false;
  };

  // Toggle audio
  const handleEnableAudio = () => {
    setAudioEnabled((prev) => !prev);
  };

  // Play next audio
  const playNextAudio = useCallback(async () => {
    if (!audioEnabled || audioQueueRef.current.length === 0) {
      isPlayingRef.current = false;
      setIsAudioComplete(true);
      return;
    }

    try {
      isPlayingRef.current = true;
      const audioData = audioQueueRef.current.shift();
      const blob = new Blob([audioData], { type: 'audio/mp3' });
      const url = URL.createObjectURL(blob);
      
      // Create and configure audio element
      const audio = new Audio();
      currentAudioRef.current = audio;

      // Return a promise that resolves when the audio finishes
      await new Promise((resolve, reject) => {
        audio.oncanplay = () => {
          audio.playbackRate = ttsSpeed;  // Set speed after audio is loaded
        };

        audio.onended = () => {
          URL.revokeObjectURL(url);
          currentAudioRef.current = null;
          resolve();
        };

        audio.onerror = (error) => {
          console.error('Error playing audio:', error);
          URL.revokeObjectURL(url);
          currentAudioRef.current = null;
          reject(error);
        };

        // Set source after attaching event listeners
        audio.src = url;
        audio.play().catch(reject);
      });

      // Play next audio in queue if any
      if (audioQueueRef.current.length > 0) {
        await playNextAudio();
      } else {
        isPlayingRef.current = false;
        setIsAudioComplete(true);
        setOutputLevel(-100);
      }

    } catch (error) {
      console.error('Error in playNextAudio:', error);
      isPlayingRef.current = false;
      setIsAudioComplete(true);
      
      if (audioQueueRef.current.length > 0) {
        await playNextAudio();
      }
    }
  }, [audioEnabled, ttsSpeed]);

  // Update the socket listener for audioData
  useEffect(() => {
    if (!audioEnabled) return;

    if (!audioDataListenerAdded.current) {
      socket.on('audioData', async (data) => {
        // Add to queue
        audioQueueRef.current.push(data);
        
        // Start playing if nothing is currently playing
        if (!isPlayingRef.current) {
          setIsAudioComplete(false);
          await playNextAudio();
        }
      });

      socket.on('audioComplete', () => {
        setIsAudioComplete(true);
      });

      audioDataListenerAdded.current = true;
    }

    return () => {
      socket.off('audioData');
      socket.off('audioComplete');
    };
  }, [audioEnabled, playNextAudio]);

  // Add cleanup function for component unmount
  useEffect(() => {
    return () => {
      // Cleanup audio on unmount
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current.src = '';
        currentAudioRef.current = null;
      }
      isPlayingRef.current = false;
      audioQueueRef.current = [];
    };
  }, []);

  // UI Rendering
  return (
    <div style={{ backgroundColor: '#000000', color: '#E0E0E0', minHeight: '100vh' }}>
      {showSilenceModal && <SilenceModal onResume={handleResume} onEnd={handleEndSession} />}
      <div
        style={{
          padding: '10px',
          borderBottom: '1px solid #333',
          backgroundColor: '#111',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            maxWidth: '1200px',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Tooltip title={isConnected ? "Connected" : "Disconnected"} arrow>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <FontAwesomeIcon 
                  icon={isConnected ? faCircle : faCircleXmark} 
                  style={{ 
                    color: isConnected ? '#4CAF50' : '#f44336',
                    fontSize: '14px'
                  }} 
                />
              </div>
            </Tooltip>
            {isListening && (
              <>
                <AudioVisualizer
                  width={150}
                  height={20}
                  dbLevel={inputLevel}
                  isActive={isSpeaking}
                  color="#4CAF50"
                  label="Input"
                />
                <AudioVisualizer
                  width={150}
                  height={20}
                  dbLevel={outputLevel}
                  isActive={isAISpeaking}
                  color="#2196F3"
                  label="Output"
                />
                <div>
                  Server:{' '}
                  {isServerProcessing ? (
                    <span style={{ color: 'orange' }}>Processing</span>
                  ) : (
                    <span style={{ color: 'gray' }}>Ready</span>
                  )}
                </div>
              </>
            )}
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '8px',
              backgroundColor: '#222',
              padding: '4px 12px',
              borderRadius: '4px',
              minWidth: '140px'
            }}>
              <span style={{ fontSize: '0.9em', color: '#888' }}>Speed:</span>
              <input
                type="range"
                min="0.5"
                max="2"
                step="0.25"
                value={ttsSpeed}
                onChange={handleSpeedChange}
                style={{
                  width: '60px',
                  accentColor: '#c59ad1e6'
                }}
              />
              <span style={{ fontSize: '0.9em', minWidth: '32px' }}>
                {ttsSpeed}x
              </span>
            </div>

            <Tooltip title={isListening ? "Pause Conversation" : "Start Conversation"} arrow>
              <button
                onClick={toggleListening}
                disabled={!isConnected}
                style={{
                  padding: '10px 20px',
                  backgroundColor: isListening ? '#c59ad1e6' : '#3a59b5',
                  color: '#E0E0E0',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: isConnected ? 'pointer' : 'not-allowed',
                }}
              >
                {isListening ? 'Pause Conversation' : 'Start Conversation'}
              </button>
            </Tooltip>
            
            <Tooltip title="Clear Conversation" arrow>
              <button
                onClick={clearConversation}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#c59ad1e6',
                  color: '#E0E0E0',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Clear
              </button>
            </Tooltip>
            
            <Tooltip title={audioEnabled ? "Disable Audio" : "Enable Audio"} arrow>
              <button
                onClick={handleEnableAudio}
                style={{
                  padding: '10px',
                  backgroundColor: audioEnabled ? '#4CAF50' : '#3a59b5',
                  color: '#E0E0E0',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon icon={audioEnabled ? faVolumeHigh : faVolumeMute} />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <audio ref={audioRef} controls style={{ display: 'none' }} />

      <VerticalTabs openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

      <Drawer
        variant="persistent"
        anchor="left"
        open={openDrawer !== null}
        sx={{
          width: 350,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 350,
            boxSizing: 'border-box',
            backgroundColor: '#111',
            border: 'none',
            borderRight: '1px solid #333',
            transition: 'all 0.2s ease-in-out',
            overflow: 'hidden',
            position: 'fixed',
            height: 'calc(100% - 60px)',
            top: '60px',
            zIndex: 1100,
            paddingLeft: '40px',
          },
        }}
      >
        <DrawerContent 
          type={openDrawer} 
          emotionalState={emotionalStates[PERSONAS.ANNE.id]}
          previousState={previousStates[PERSONAS.ANNE.id]}
          emotionalAnalysis={emotionalAnalysis[PERSONAS.ANNE.id]?.analysis || ''}
          conversation={conversation}
          persona={PERSONAS.ANNE}
          onClose={() => setOpenDrawer(null)}
        />
      </Drawer>

      <div
        style={{
          padding: '20px',
          width: `calc(100% - ${(openDrawer !== null ? 350 : 0) + (rightDrawerOpen !== null ? 350 : 0)}px)`,
          marginLeft: openDrawer !== null ? '350px' : '0',
          marginRight: rightDrawerOpen !== null ? '350px' : '0',
          transition: 'all 0.2s ease-in-out',
        }}
      >
        {/* Centered persona image container */}
        <div style={{
          height: 'calc(100vh - 120px)',
          backgroundColor: '#000000',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          position: 'relative',
          gap: '20px',
        }}>
          <div style={{
            position: 'absolute',
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            padding: '8px 16px',
            borderRadius: '4px',
            color: '#c59ad1e6',
            fontSize: '1em',
            zIndex: 1,
          }}>
            {emotionalAnalysis?.emotionalState || 'interested_med1'}
          </div>
          
          {/* Background and personas container */}
          <div style={{
            width: '100%',
            height: '75%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            position: 'relative',
            gap: '20px',
            backgroundImage: 'url("/images/setting/classroom.png")',
            backgroundSize: 'cover',
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
            borderRadius: '12px 12px 0 0',
          }}>
            {/* Anne's container */}
            <div style={{
              flex: 1,
              height: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              position: 'relative',
            }}>
              <img
                key={emotionalStates[PERSONAS.ANNE.id]?.imageState}
                src={getPersonaImagePath(PERSONAS.ANNE, emotionalStates[PERSONAS.ANNE.id])}
                alt="Anne"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  objectPosition: 'bottom',
                }}
                onError={(e) => {
                  console.error('Image failed to load:', e.target.src);
                  e.target.src = `/images/personas/${PERSONAS.ANNE.id}/interested_med1.png`;
                }}
              />
            </div>
            
            {/* Dan's container */}
            <div style={{
              flex: 1,
              height: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              position: 'relative',
            }}>
              <img
                src={getPersonaImagePath(PERSONAS.DAN, emotionalStates[PERSONAS.DAN.id])}
                alt="Dan"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  objectPosition: 'bottom',
                }}
              />
            </div>
          </div>

          {/* Text area below the background */}
          <div style={{
            width: '100%',
            height: '25%',
            backgroundColor: '#000000',
            borderRadius: '0 0 12px 12px',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {conversation[0]?.type === 'ai' && (
              <div style={{
                color: '#fff',
                maxWidth: '80%',
                textAlign: 'center',
                opacity: conversation[0].complete === false ? 0.7 : 1,
                transition: 'opacity 0.3s ease',
                fontSize: '1.1em',
                lineHeight: '1.5',
              }}>
                <span style={{ 
                  color: '#c59ad1e6', 
                  fontWeight: 'bold',
                  marginRight: '10px'
                }}>
                  {conversation[0].content?.toLowerCase().includes('*anne*') 
                    ? 'Anne (right):' 
                    : 'Dan (left):'
                  }
                </span>
                {conversation[0].content.replace(/^\s*\*(?:anne|dan)\*\s*/i, '')}
              </div>
            )}
          </div>
        </div>
      </div>

      <Drawer
        variant="persistent"
        anchor="right"
        open={rightDrawerOpen !== null}
        sx={{
          width: 350,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 350,
            boxSizing: 'border-box',
            backgroundColor: '#111',
            border: 'none',
            borderLeft: '1px solid #333',
            transition: 'all 0.2s ease-in-out',
            overflow: 'hidden',
            position: 'fixed',
            height: 'calc(100% - 60px)',
            top: '60px',
            zIndex: 1100,
            paddingRight: '40px',
          },
        }}
      >
        <EmotionalState
          state={emotionalStates[PERSONAS.DAN.id]}
          previousState={previousStates[PERSONAS.DAN.id]}
          analysis={emotionalAnalysis[PERSONAS.DAN.id]?.analysis || ''}
          persona={PERSONAS.DAN}
        />
      </Drawer>

      {/* Add right vertical tab */}
      <div style={{ 
        position: 'fixed', 
        right: rightDrawerOpen !== null ? '350px' : '0', 
        top: '80px', 
        zIndex: 1200,
        transition: 'right 0.2s ease-in-out',
      }}>
        <Tooltip 
          title="Emotional State" 
          placement="left"
          arrow
        >
          <div
            onClick={() => setRightDrawerOpen(rightDrawerOpen === 'emotional' ? null : 'emotional')}
            style={{
              backgroundColor: '#333',
              border: '1px solid #333',
              borderRight: 'none',
              borderRadius: '4px 0 0 4px',
              cursor: 'pointer',
              padding: '12px 8px',
              marginBottom: '4px',
              userSelect: 'none',
              color: rightDrawerOpen === 'emotional' ? '#fff' : '#c59ad1e6',
              fontSize: '20px',
            }}
            className={rightDrawerOpen === 'emotional' ? 'pulse-animation' : ''}
          >
            <FontAwesomeIcon icon={faChartColumn} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

const getEmotionalStateImage = (emotionalState) => {
  if (!emotionalState) return null;
  return `/images/personas/0029/${emotionalState}.png`;
};

// Add some CSS for the drawer content
const drawerContentStyles = `
  @keyframes pulse {
    0% {
      transform: scale(1);
      color: #c59ad1e6;
    }
    50% {
      transform: scale(1.2);
      color: #ffffff;
    }
    100% {
      transform: scale(1);
      color: #c59ad1e6;
    }
  }

  .pulse-animation {
    animation: pulse 1.5s infinite ease-in-out;
  }
`;

// Add the styles to the document
const style = document.createElement('style');
style.textContent = drawerContentStyles;
document.head.appendChild(style);

export default App;

