import React, { useEffect, useRef } from 'react';

const AudioVisualizer = ({ 
  width = 100, 
  height = 20, 
  dbLevel = -100,
  isActive = false,
  color = '#4CAF50',
  label = 'Audio'
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    
    // Clear canvas
    ctx.clearRect(0, 0, width, height);
    
    // Draw background
    ctx.fillStyle = '#1a1a1a';
    ctx.fillRect(0, 0, width, height);
    
    // Calculate level width
    // Map dB range (-100 to 0) to pixel width (0 to width)
    const levelWidth = Math.max(0, Math.min(width, 
      ((dbLevel + 100) / 100) * width
    ));
    
    // Draw level meter
    const gradient = ctx.createLinearGradient(0, 0, width, 0);
    gradient.addColorStop(0, color);
    gradient.addColorStop(0.8, color);
    gradient.addColorStop(1, '#ff4444');
    
    ctx.fillStyle = isActive ? gradient : '#333';
    ctx.fillRect(0, 0, levelWidth, height);
    
    // Draw border
    ctx.strokeStyle = '#333';
    ctx.strokeRect(0, 0, width, height);

    // Draw tick marks
    ctx.strokeStyle = '#444';
    ctx.beginPath();
    for (let x = width / 4; x < width; x += width / 4) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
    }
    ctx.stroke();

  }, [dbLevel, width, height, isActive, color]);

  return (
    <div className="flex items-center gap-2">
      <div className="w-16 text-sm text-gray-300">{label}:</div>
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        className="rounded"
      />
    </div>
  );
};

export default AudioVisualizer;