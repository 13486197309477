export const PERSONAS = {
  ANNE: '0029',
  DAN: '0007'
};

export const DEFAULT_EMOTIONAL_STATE = {
  valence: 0,
  arousal: 5,
  dominance: 5,
  openness: 5,
  trust: 5,
  pain: 0,
  anxiety: 5
}; 